import React from "react";
import { Bell, Camera, CaretDown, CaretLeft, CaretRight, CaretUp, Check, Circle, CircleHalf, CloudArrowUp, Clock, DownloadSimple, Envelope, EnvelopeSimple, FileDashed, FileDoc, FileXls, FilePdf, FloppyDisk, Funnel, Gear, GearSix, Globe, IconWeight, Link, LinkSimpleBreak, List, ListBullets, ListChecks, MagnifyingGlass, Moon, PencilSimple, Phone, Question, Sidebar, SignOut, Star, Sun, SquaresFour, UserGear, TextIndent, Trash, Warning, WarningCircle, X, CheckCircle, Info } from "@phosphor-icons/react";

export interface PhosphorIconProps {
  className?: string
  iconSize?: "small" | "regular" | "large"
  iconColor?: "gray" | "white" | "error"
  iconWeight?: IconWeight;
}

export const PhosphorBell = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Bell size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorCamera = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Camera size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorCaretDown = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <CaretDown size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorCaretLeft = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <CaretLeft size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorCaretRight = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <CaretRight size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorCaretUp = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <CaretUp size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorCheck = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Check size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorCheckCircle = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <CheckCircle size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
}

export const PhosphorCircle = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Circle size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorCircleHalf = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <CircleHalf size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorClock = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Clock size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorArrowUp = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <CloudArrowUp size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};


export const PhosphorDownloadSimple = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <DownloadSimple size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorEnvelope = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Envelope size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorEnvelopeSimple = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <EnvelopeSimple size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorFileDashed = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <FileDashed size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorFilePdf = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <FilePdf size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorFileDocx = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <FileDoc size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorFileXlsx = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <FileXls size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorFloppy = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <FloppyDisk size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorFunnel = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Funnel size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorGear = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Gear size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorGearSix = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <GearSix size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorGlobe = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Globe size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorInfo = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Info size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorLink = (props: PhosphorIconProps) => {
  const weight = props.iconWeight ? props.iconWeight : "bold";

  return (<IconWrapper {...props}  >
    <Link size={GetSize(props.iconSize)} color={GetColor(props)} weight={weight} />
  </IconWrapper>)
};

export const PhosphorLinkBreak = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}  >
    <LinkSimpleBreak size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorList = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <List size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorListChecks = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <ListChecks size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorListBullets = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <ListBullets size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorMagnifyingGlass = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <MagnifyingGlass size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorMoon = (props: PhosphorIconProps) => {
  const weight = props.iconWeight ? props.iconWeight : "fill";

  return (<IconWrapper {...props}>
    <Moon size={GetSize(props.iconSize)} color={GetColor(props)} weight={weight} />
  </IconWrapper>)
};

export const PhosphorPencil = (props: PhosphorIconProps) => {

  return (<IconWrapper {...props}>
    <PencilSimple size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorPhone = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Phone size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorQuestion = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Question size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorSidebar = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Sidebar size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorSignOut = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <SignOut size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorSquaresFour = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <SquaresFour size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorSun = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Sun size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorStar = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Star size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorTextIndent = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <TextIndent size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorTrash = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Trash size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorUserGear = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <UserGear size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorWarning = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <Warning size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorWarningCircle = (props: PhosphorIconProps) => {
  return (<IconWrapper {...props}>
    <WarningCircle size={GetSize(props.iconSize)} color={GetColor(props)} weight={props.iconWeight} />
  </IconWrapper>)
};

export const PhosphorX = (props: PhosphorIconProps) => {

  const weight = props.iconWeight ? props.iconWeight : "bold";

  return (<IconWrapper {...props}>
    <X size={GetSize(props.iconSize)} color={GetColor(props)} weight={weight} />
  </IconWrapper>)
};

interface IconWrapperProps extends PhosphorIconProps {
  children: React.ReactNode;
}

const IconWrapper: React.FC<IconWrapperProps> = ({ children }) => {
  return <span>
    {children}
  </span>
};

const GetColor = (props: PhosphorIconProps) => {
  switch (props.iconColor) {
    case "gray":
      return "var(--text-color-lighter)";
    case "white":
      return "white";
    case "error":
      return "var(--text-color-error)";
    default:
      return null;
  }
}

const GetSize = (size: string) => {
  switch (size) {
    case "small":
      return 14;
    case "large":
      return 24;
    case "regular":
    default:
      return 18;
  }
}

export const PhosphorIconForExportOption = ({ type }: { type: server.dto.ExportType }) => {

  if (type.action === "store") return PhosphorArrowUp({});

  switch (type.extension) {
    case "docx":
      return PhosphorFileDocx({});
    case "pdf":
      return PhosphorFilePdf({});
    case "xlsx":
      return PhosphorFileXlsx({});
    default:
      return PhosphorFileDashed({});
  }
};