import React, { createContext, useCallback } from "react";

interface Panel {
  jsx: JSX.Element
  id: string
  width: number
};

interface PreviewPanelContextValue {
  panel: Panel | null
  isClosing: boolean
  isOpening: boolean
  isOpen: boolean
  hasBack: boolean
  back: () => void
  openPanel: (panel: Panel) => void
  updatePanel: (jsx: JSX.Element) => void
  closePanel: () => void
}

const defaultPreviewPanelContext = {
  panel: null,
  isClosing: false,
  isOpening: false,
  isOpen: false,
  hasBack: false,
  back: () => { },
  openPanel: () => { },
  updatePanel: () => { },
  closePanel: () => { }
};

export const PreviewPanelContext = createContext<PreviewPanelContextValue>(defaultPreviewPanelContext);

export const PreviewPanelProvider = (props: React.PropsWithChildren<unknown>) => {
  const [panels, setPanels] = React.useState<Panel[]>([]);
  const [isOpening, setIsOpening] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const { children } = props;
  const panel = panels.length > 0 ? panels[panels.length - 1] : null;

  const openPanel = useCallback((p: Panel) => {
    if (isOpening || isClosing) {
      return;
    }

    setPanels((state) => [...state, p]);
    setIsOpening(true);
    setTimeout(() => {
      setIsOpening(false);
    }, 300);
  }, [isOpening, isClosing]);

  const updatePanel = useCallback((jsx: JSX.Element) => {
    if (panel) {
      setPanels(state => state.map((p, idx) => idx === panels.length - 1 ? { ...p, jsx } : p));
    }
  }, [panel]);

  const closePanel = useCallback(() => {
    if (isOpening || isClosing) {
      return;
    }

    if (panel) {
      setIsClosing(true);
      setTimeout(() => {
        setIsClosing(false);
        setPanels([]);
      }, 300);
    }
  }, [isOpening, isClosing]);

  const back = () => {
    setPanels(state => state.filter((_, idx) => idx !== panels.length - 1));
  }

  return (
    <PreviewPanelContext.Provider value={{
      panel,
      isClosing,
      isOpening,
      isOpen: !(isOpening || isClosing) && panel !== null,
      hasBack: panels.length > 1,
      back,
      openPanel,
      updatePanel,
      closePanel
    }}>
      {children}
    </PreviewPanelContext.Provider>
  );
};