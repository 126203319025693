import { createAction } from "redux-actions";
import { Actions } from "../actions/actions";
import * as Sentry from "@sentry/browser";
// action creators
const displayError = (error: Error, message?: string, container?: string): any => (dispatch) => {
  if (error) {
    console.error(error);
    Sentry.captureException(error);
  }

  if (container) {
    dispatch(showErrorContainer(message));
  } else {
    dispatch(showErrorDialog(message));
  }
};

const hideErrorDialog = createAction(
  Actions.ErrorHandling.ERROR_HIDE
);

const showErrorDialog = createAction<string, string>(
  Actions.ErrorHandling.ERROR_DISPLAY,
  (message: string) => message
);

const showErrorContainer = createAction<string, string>(
  Actions.ErrorHandling.ERROR_CONTAINER,
  (message: string) => message
);

export {
  displayError,
  hideErrorDialog,
  showErrorDialog
};
