import { useInfiniteQuery, type UseInfiniteQueryResult } from "@tanstack/react-query";
import { AxiosPromise } from "axios";
import { type ButtonTypes } from "components/Atoms/Button";
import ButtonGroup from "components/Atoms/ButtonGroup";
import { NoDataAvailableDiv } from "components/NoDataAvailableDiv";
import { Loading } from "components/panels";
import React from "react";
import { useInView } from "react-intersection-observer";
import { Input } from "semantic-ui-react";
import { useDebounce } from "utilities/usehooks";
import { getPaginatedInfiniteQueryData } from "./utils";
import { SelectableListKeyboardNavigationGroup } from "keyboardNavigation/KeyboardNavigationPresets";

interface FilterButtonType {
  type: ButtonTypes
  isActive: boolean
  label: string
  group: string
  onClick: () => void
}

type HasKeywordAndPagination = server.dto.IPaginationParameters & { keyword?: string };
interface ModalPickerProps<TItem, TId extends number | string, TInstruction extends HasKeywordAndPagination> {
  instruction: TInstruction
  onInstructionChange: (instruction: TInstruction) => void
  queryKey: string;
  api: (instruction: TInstruction) => AxiosPromise<server.dto.ApiResponseWrapper<TItem[]>>;
  onItemSelected: (item: TItem) => void

  excludeKeys?: TId[]

  renderListItem: (item: TItem) => JSX.Element
  getId: (item: TItem) => TId
  getError: (query: UseInfiniteQueryResult<server.dto.ApiResponseWrapper<TItem[]>, unknown>) => string

  filter1?: {
    leftButton: Omit<FilterButtonType, "group">
    rightButton: Omit<FilterButtonType, "group">
  }
  filter2?: {
    leftButton: Omit<FilterButtonType, "group">
    rightButton: Omit<FilterButtonType, "group">
  }
  hideKeywordFilter?: boolean
  otherFilters?: JSX.Element;

  map?: (items: TItem[], searchQuery: string) => TItem[]
}

const FilterButton = (props: FilterButtonType) => {
  return <ButtonGroup.Button
    group={props.group}
    active={props.isActive}
    onClick={props.onClick}
    type="secondary"
  >{props.label}</ButtonGroup.Button>;
};

const PickerList = <TItem extends unknown, TId extends number | string, TInstruction extends HasKeywordAndPagination>(props: ModalPickerProps<TItem, TId, TInstruction>) => {
  const { ref, inView } = useInView();
  const inputRef = React.useRef<Input>(null);

  const debouncedInstruction = useDebounce(props.instruction, 300);

  const query = useInfiniteQuery({
    keepPreviousData: true,
    queryKey: [props.queryKey, debouncedInstruction],
    queryFn: async ({ pageParam = 1 }) => props.api({ ...debouncedInstruction, page: pageParam }).then(r => r.data),
    getNextPageParam: (wrapper) => wrapper.pagination.page !== wrapper.pagination.pageCount ? wrapper.pagination.page + 1 : undefined,
  });

  React.useEffect(() => {
    if (inView) {
      query.fetchNextPage();
    }
  }, [inView]);
  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  const excludeKeyMap = new Set(props.excludeKeys);

  const data = getPaginatedInfiniteQueryData(query);

  let filteredData = data.filter(i => !excludeKeyMap.has(props.getId(i)));

  if (props.map) {
    filteredData = props.map(filteredData, debouncedInstruction.keyword || "");
  }

  let state: "noResult" | "networkError" | "apiError" | "showResults" | "initialLoading";
  if (query.isError) {
    state = "networkError";
  } else if (props.getError(query)) {
    state = "apiError";
  } else if (query.isLoading && filteredData.length === 0) {
    state = "initialLoading";
  } else if (filteredData.length === 0) {
    state = "noResult";
  } else {
    state = "showResults";
  }

  return <>
    <div className="flex-column gap">
      {props.filter1 != null && <div>
        <ButtonGroup.Group>
          <FilterButton
            group="filter1"
            type={props.filter1.leftButton.type}
            label={props.filter1.leftButton.label}
            onClick={props.filter1.leftButton.onClick}
            isActive={props.filter1.leftButton.isActive}
          />
          <FilterButton
            group="filter1"
            type={props.filter1.rightButton.type}
            label={props.filter1.rightButton.label}
            onClick={props.filter1.rightButton.onClick}
            isActive={props.filter1.rightButton.isActive}
          />
        </ButtonGroup.Group>
      </div>}
      {props.filter2 != null && <div>
        <ButtonGroup.Group>
          <FilterButton
            group="filter2"
            type={props.filter2.leftButton.type}
            label={props.filter2.leftButton.label}
            onClick={props.filter2.leftButton.onClick}
            isActive={props.filter2.leftButton.isActive}
          />
          <FilterButton
            group="filter2"
            type={props.filter2.rightButton.type}
            label={props.filter2.rightButton.label}
            onClick={props.filter2.rightButton.onClick}
            isActive={props.filter2.rightButton.isActive}
          />
        </ButtonGroup.Group>
      </div>}
    </div>

    {props.hideKeywordFilter !== true && <div>
      <Input
        ref={inputRef}
        value={props.instruction.keyword}
        onChange={(_, { value }) => {
          props.onInstructionChange({ ...props.instruction, keyword: value, page: 1 });
        }}
        fluid
        icon="search"
        placeholder={RESX.GeneralLabels.SearchByName}
      />
    </div>}

    {props.otherFilters}

    <div className="flex-column gap-small scrollbox-auto grow1" style={{ maxHeight: 400 }}>
      {state === "networkError" && <div className="message ui red">{RESX.GeneralLabels.SomethingUnexpectedHappened}</div>}
      {state === "apiError" && <div className="message ui red"> {props.getError(query)}</div>}
      {state === "noResult" && <NoDataAvailableDiv color="yellow" />}
      {state === "initialLoading" && <Loading />}
      {filteredData.map(item => <div
        tabIndex={0}
        {...SelectableListKeyboardNavigationGroup("pickerList")}
        key={props.getId(item)}
        className="s1_selectmenu_item gray-100 focusable hoverable padding"
        onClick={() => props.onItemSelected(item)}
      >
        {props.renderListItem(item)}
      </div>)}
      <div style={{ height: 1 }} ref={ref}>&nbsp;</div>
    </div>
  </>;
};

export default PickerList;