import React from "react";
import { type ValidatorMap, ValidationContext, type ScrollableRef, ScrollableItems, ValidationType, FormError } from "./FormContext";
import { useDebounce } from "utilities/usehooks";

export const parseValidationResultToError = (validationResult: boolean | string): FormError => {
  return {
    hasError: typeof validationResult === "string" || !Boolean(validationResult),
    errorMessage: typeof validationResult === "string" ? validationResult : undefined
  }
}
export const useValidators = () => {
  // TODO: these should be refs?
  const [validators, setValidators] = React.useState<ValidatorMap>({});
  const [validationResult, setValidationResult] = React.useState<ValidationResult>({ hasErrors: false, failedKeys: [], scrollToFirstError: () => { } });


  const setValidator = (moreValidators: ValidatorMap) => setValidators(prevState => ({ ...prevState, ...moreValidators }));
  const removeValidator = (key: string) => setValidators(prevState => {
    const { [key]: _, ...rest } = prevState;
    return rest;
  });

  const debouncedValidators = useDebounce(validators, 500);

  React.useEffect(() => {
    validateAll(debouncedValidators, "automatic").then(validationResult => {
      setValidationResult(validationResult);

      const successfulValidators = Object.keys(debouncedValidators)
        .filter(key => !validationResult.failedKeys.some(f => f.key === key))
        .map(key => debouncedValidators[key]);

      // If a validator was previously invalid, but is now valid, we want to remove the error
      for (const validator of successfulValidators) {
        if (validator.type === "automatic" && validator.error) {
          validator.setError({ hasError: false });
        }
      }
    });
  }, [debouncedValidators]);

  const validatorSettings = {
    validators,
    setValidator,
    removeValidator
  };

  return {
    validatorSettings,
    validationResult,
    validateAll: async () => {
      const result = await validateAll(validators, "manual")

      result.failedKeys.forEach(f => {
        validators[f.key].setError(f.error);
      });
      return result;
    }
  };
};

export type LegacyErrorHandling = {
  error: FormError
  setError: (error: FormError) => void
}

type ValidationArgs<TRef extends ScrollableItems, TValue> = {
  key: string // Unique identifier for the validator
  title: string // Title of the field. This can be shown in error messages
  value: TValue // The current value of the form field
  validate: (newValue: TValue) => Promise<boolean | string> // The validation function
  ref?: React.RefObject<TRef> // The ref of the form field. If none is provided, one will be created automatically
  dependencies?: React.DependencyList // Dependencies for the validation function
  legacyError?: LegacyErrorHandling // Custom error handling if necessary. Otherwise defaults to simple useState
  type?: ValidationType // If "automatic" or undefined, the field will be revalidated on change. Use "manual" if validation contains side-effects (e.g. API calls), so you have to validate it manually onChange/onBlur
}

type ValidationArgsWithoutValidateFunction<TRef extends ScrollableItems, TValue> = Omit<ValidationArgs<TRef, TValue>, "validate">;

export const useValidate = <TRef extends ScrollableItems, TValue>({
  key,
  title,
  value,
  validate,
  ref,
  dependencies = [value],
  legacyError,
  type = "automatic"
}: ValidationArgs<TRef, TValue>) => {
  ref = ref ?? React.useRef<TRef>();
  const [error, setError] = legacyError
    ? [legacyError.error, legacyError.setError]
    : React.useState<FormError>({ hasError: false, errorMessage: "" });

  if (key) {
    const formContext = React.useContext(ValidationContext);
    React.useEffect(() => {
      formContext.setValidator({
        [key]: ({
          validate: () => validate(value),
          setError,
          title,
          ref,
          type,
          error
        })
      });

      return () => {
        formContext.removeValidator(key);
      }
    }, [...dependencies, error.hasError, error.errorMessage]);
  }

  const validateAndSetError = async () => {
    const validationResult = await validate(value);

    const hasError = parseValidationResultToError(validationResult);
    setError(hasError);
  };


  return {
    error,
    setError,
    ref,
    validate,
    validateAndSetError
  }
}

export const useValidateIsNotNull = <T extends ScrollableItems>(args: ValidationArgsWithoutValidateFunction<T, unknown>) =>
  useValidate<T, unknown>({ ...args, validate: async (newValue) => newValue != null });

export const useValidateIsNotEmptyArray = <T extends ScrollableItems>(args: ValidationArgsWithoutValidateFunction<T, unknown[]>) =>
  useValidate<T, unknown[]>({ ...args, validate: async (newValue) => newValue.length > 0 });

export const useValidateIsNotEmptyString = <T extends ScrollableItems>(args: ValidationArgsWithoutValidateFunction<T, string>) =>
  useValidate<T, string>({ ...args, validate: async (newValue) => newValue != null && newValue.trim().length > 0 });

export const useValidateIsAlwaysValid = <T extends ScrollableItems>(
  key: string,
  title: string,
) => useValidate<T, unknown>({ key, title, value: null, validate: async () => true });

interface KeyAndRef {
  key: string
  title: string
  ref: ScrollableRef
  error: FormError
};

export interface ValidationResult {
  hasErrors: boolean
  failedKeys: KeyAndRef[]
  scrollToFirstError: () => void
};

const validateAll = async (validators: ValidatorMap, validationType: ValidationType): Promise<ValidationResult> => {

  const validatorKeys = Object
    .keys(validators);

  const promises = validatorKeys
    .map(key => {
      // On manual validation, everything needs to be validated. We're probably about to submit a form or something
      // On automatic validation, only automatic validators need to be validated
      if (validationType === "manual" || validators[key].type === "automatic") {
        return validators[key].validate()
      }

      const error = validators[key].error;
      if (error.errorMessage) return Promise.resolve(error.errorMessage);
      return Promise.resolve(!error.hasError)
    });

  // Check if all validators have refs
  const validatorsWithoutRefs = validatorKeys.reduce((acc, key) => validators[key].ref?.current == null ? [...acc, key] : acc, []);
  if (validatorsWithoutRefs.length > 0) {
    console.warn("Missing refs for validators", validatorsWithoutRefs);
  }

  const promiseResults = await Promise.all(promises);

  const failedValidators = validatorKeys
    .map<KeyAndRef>((key, idx) => ({
      key,
      ref: validators[key].ref,
      title: validators[key].title,
      error: parseValidationResultToError(promiseResults[idx])
    }))
    .filter(({ error: validationResult }) => validationResult.hasError);

  const scrollToFirstError = () => {
    if (failedValidators.length === 0) return;

    // We want to scroll to the first failed validator that has a ref
    // The "first" being the element that's the highest up on the page
    const highestElement = failedValidators.reduce((lowestYElementValidatedResult, validatedResult) => {
      if (!validatedResult.ref?.current) return lowestYElementValidatedResult;
      if (!lowestYElementValidatedResult?.ref?.current) return validatedResult;

      const rect = validatedResult.ref.current.getBoundingClientRect();
      if (rect.top < lowestYElementValidatedResult.ref.current.getBoundingClientRect().top) {
        return validatedResult;
      }

      return lowestYElementValidatedResult;
    }, undefined);

    if (highestElement) {
      window.scrollTo({
        top: window.scrollY + highestElement.ref.current.getBoundingClientRect().top - 300,
        behavior: "smooth"
      });
    }
  }

  return {
    hasErrors: failedValidators.length > 0,
    failedKeys: failedValidators,
    scrollToFirstError
  };
};